
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import UserManagementService from "@/services/user-management";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "@/services/filter-service";
import utils from "@/utils/utils";

@Component({
  components: { Header, Loader, PopUp, FilterComponent },
})
export default class UserManagement extends Vue {
  public currentPage = 1;
  loading = true;
  public selected_permissions: any = [];
  module = [];
  group = [];
  module_name = [];
  public screenObject: any = {};
  openModule = false;
  saveData = true;
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  formatPermissions(permissionArray: any) {
    return permissionArray.join(', ');
  }
  toggleModuleDropdown() {
    this.openModule = !this.openModule;
    this.getScreenModule();
  }
  public permission_options: any = [];
  group_options = [];
  public module_options: any = [];
  retreive() {
    this.loading = true;
    UserManagementService.get_all_screens()
      .then((res) => {
        this.selected_permissions = res.data.data;
        this.saveData = true;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  get rows() {
    return this.selected_permissions.length;
  }
  paginatedItems() {
    const start = (this.currentPage - 1) * 20;
    const end = start + 20;
    return this.selected_permissions.slice(start, end);
  }
  getScreenModule() {
    this.loading = true;
    UserManagementService.get_screen_modules()
      .then((res) => {
        this.module_options = res.data.module;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  mounted() {
    this.retreive();
    this.getScreenModule();
  }
  saveScreen() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.screenObject, ['name', 'description', 'routes'])
    if (error_list.length > 0 || this.module === null || this.module.length === 0 || this.module === undefined) {
      this.error = utils.generateErrorMessage(error_list.concat(this.module === null || this.module.length === 0 || this.module === undefined ? 'module' : []));
    }
    else {
      const selectedModuleIds = this.module.map((module) => {
        const option = this.module_options.find((option: any) => option.api_route === module);
        return option.id;
      });

      const screenObject = {
        name: this.screenObject.name,
        description: this.screenObject.description,
        routes: this.screenObject.routes,
        component: this.screenObject.component,
        module_name: selectedModuleIds
      }
      this.loading = true;
      UserManagementService.create_screen(screenObject)
        .then((res) => {
          this.resetScreen();
          this.retreive();
          this.refreshFilters();
          this.showModal("Created Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  editScreenToggle(data: any) {
    this.saveData = false;
    this.screenObject = data;
    this.module = this.screenObject.module_name__api_route.slice();
    this.$root.$emit("bv::toggle::collapse", "sidebar-variant-create-screen");
  }
  editScreen() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.screenObject, ['name', 'description', 'routes'])
    if (error_list.length > 0 || this.module === null || this.module.length === 0 || this.module === undefined) {
      this.error = utils.generateErrorMessage(error_list.concat(this.module === null || this.module.length === 0 || this.module === undefined ? 'module' : []));
    }
    else {
      const module_id: any = this.module;
      const selectedModuleIds = module_id.map((module: any) => {
        const option = this.module_options.find((option: any) => option.api_route === module);
        return option?.id;
      });
      this.screenObject.module_name = selectedModuleIds;
      this.loading = true;
      UserManagementService.update_screen(this.screenObject.id, this.screenObject)
        .then((res) => {
          this.resetScreen();
          this.retreive();
          this.refreshFilters();
          this.showModal("Updated Successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  deleteScreen(item: any) {
    this.loading = true;
    UserManagementService.delete_screen(item.id)
      .then((res) => {
        this.retreive();
        this.refreshFilters();
        this.showModal("Deleted Successfully", [], "success");
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
  resetScreen() {
    this.error = "";
    this.saveData = true;
    this.screenObject = {
      screenTitle: '',
      description: '',
      route: '',
      component: '',
    }
    this.module = [];
    for (let field of this.filter_fields) {
      field.value = "";
    }
  }
  filter_fields = [
    { type: "text", label: "Name", name: "name", value: "", param_name: "name", filter_type: "simple", },
    { type: "text", label: "Route", name: "route", value: "", param_name: "routes", filter_type: "simple", },
    { type: "text", label: "Module", name: "module", value: "", param_name: "module", filter_type: "simple", },
  ];
  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.get_screens(simpleFilteredObj).then((data) => {
        if (
          data.data.data !== undefined &&
          data.data.data !== null
        ) {
          this.selected_permissions = data.data.data;
          this.loading = false;
        } else {
          this.retreive();
        }
      });
    }
  }
  refreshFilters() {
    this.resetScreen();
  }
}
